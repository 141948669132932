import React, { useState } from "react";
import {
  Card,
  Button,
  Form,
  Input,
  message,
  Typography,
  Space,
  ConfigProvider,
  theme,
} from "antd";
import { SaveOutlined, ReloadOutlined } from "@ant-design/icons";
import { config } from "./config/index";

const { Title } = Typography;
const { TextArea } = Input;

const AdminSettings: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // 获取Token
  const getAuthToken = () => localStorage.getItem("authToken");

  // 设置深色主题
  const darkTheme = {
    algorithm: theme.darkAlgorithm,
    token: {
      colorPrimary: "#1668dc",
      colorBgContainer: "#141414",
      colorBgElevated: "#1f1f1f",
      borderRadius: 6,
    },
  };

  // 获取当前RMAPI Token
  const fetchCurrentToken = async () => {
    try {
      const response = await fetch(
        `${config.API_BASE_URL}/api/admin/rmapi/token`,
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch current token");
      }

      const data = await response.json();
      form.setFieldsValue({ token: data.token });
    } catch (error) {
      message.error("获取当前Token失败");
    }
  };

  // 更新RMAPI Token
  const updateRMAPIToken = async (values: { token: string }) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${config.API_BASE_URL}/api/admin/rmapi/token`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAuthToken()}`,
          },
          body: JSON.stringify({
            token: values.token,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update token");
      }

      message.success("Token更新成功");
    } catch (error) {
      message.error("更新Token失败");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchCurrentToken();
  }, []);

  return (
    <ConfigProvider theme={darkTheme}>
      <Card style={{ marginTop: "16px" }}>
        <Space direction="vertical" style={{ width: "100%" }} size="large">
          <Space style={{ justifyContent: "space-between", display: "flex" }}>
            <Title level={3} style={{ margin: 0 }}>
              RMAPI设置
            </Title>
            <Button
              icon={<ReloadOutlined />}
              onClick={() => fetchCurrentToken()}
            >
              刷新
            </Button>
          </Space>

          <Form
            form={form}
            onFinish={updateRMAPIToken}
            layout="vertical"
            style={{ maxWidth: "800px" }}
          >
            <Form.Item
              name="token"
              label="RMAPI Token"
              rules={[{ required: true, message: "请输入RMAPI Token" }]}
            >
              <TextArea
                rows={4}
                placeholder="请输入RMAPI Token"
                style={{ fontFamily: "monospace" }}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                icon={<SaveOutlined />}
              >
                保存Token
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Card>
    </ConfigProvider>
  );
};

export default AdminSettings;
