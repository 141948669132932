import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  Tabs,
  message,
  ConfigProvider,
  theme,
  Typography,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  HighlightOutlined,
} from "@ant-design/icons";
import { config } from "./config/index";

const { Title } = Typography;

// API endpoints
const LOGIN_URL = `${config.API_BASE_URL}/api/auth/login`;
const REGISTER_URL = `${config.API_BASE_URL}/api/auth/register`;

const AuthSystem = () => {
  const [activeTab, setActiveTab] = useState("login");
  const [loading, setLoading] = useState(false);
  const [loginForm] = Form.useForm();
  const [registerForm] = Form.useForm();

  // 设置深色主题
  const darkTheme = {
    algorithm: theme.darkAlgorithm,
    token: {
      colorPrimary: "#1668dc",
      colorBgContainer: "#141414",
      colorBgElevated: "#1f1f1f",
      borderRadius: 6,
    },
  };

  // Token存储函数
  const storeAuthToken = (token: string) => {
    localStorage.setItem("authToken", token);
    localStorage.setItem("lastLoginTime", new Date().toISOString());
  };

  // 用户信息存储函数
  const storeUserInfo = (user: any) => {
    localStorage.setItem("userInfo", JSON.stringify(user));
  };

  // 登录处理函数
  const handleLogin = async (values: { username: any; password: any }) => {
    setLoading(true);
    try {
      const response = await fetch(LOGIN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: values.username,
          password: values.password,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "登录失败");
      }

      // 存储认证信息
      storeAuthToken(data.token);
      storeUserInfo(data.user);

      message.success("登录成功！");

      // 可以添加登录成功后的回调，比如跳转到主页
      window.location.href = "/";
    } catch (error) {
      message.error("登录失败，请重试！");
    } finally {
      setLoading(false);
    }
  };

  // 注册处理函数
  const handleRegister = async (values: {
    username: any;
    email: any;
    password: any;
    invitationCode: any;
  }) => {
    setLoading(true);
    try {
      const response = await fetch(REGISTER_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: values.username,
          email: values.email,
          password: values.password,
          invitationCode: values.invitationCode,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "注册失败");
      }

      // 存储认证信息
      storeAuthToken(data.token);
      storeUserInfo(data.user);

      message.success("注册成功！");
      setActiveTab("login");

      // 清空表单
      registerForm.resetFields();
    } catch (error) {
      message.error("注册失败，请重试！");
    } finally {
      setLoading(false);
    }
  };

  // 登录表单
  const LoginForm = () => (
    <Form
      form={loginForm}
      name="login"
      onFinish={handleLogin}
      autoComplete="off"
      style={{ maxWidth: 300, margin: "0 auto" }}
    >
      <div style={{ textAlign: "center", marginBottom: "24px" }}>
        <Title level={3} style={{ color: "#1668dc", margin: 0 }}>
          冲鸭沙箱登录
        </Title>
      </div>

      <Form.Item
        name="username"
        rules={[{ required: true, message: "请输入用户名！" }]}
      >
        <Input
          prefix={<UserOutlined />}
          placeholder="用户名"
          size="large"
          disabled={loading}
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: "请输入密码！" }]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="密码"
          size="large"
          disabled={loading}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          size="large"
          loading={loading}
        >
          登录
        </Button>
      </Form.Item>
    </Form>
  );

  // 注册表单
  const RegisterForm = () => (
    <Form
      form={registerForm}
      name="register"
      onFinish={handleRegister}
      autoComplete="off"
      style={{ maxWidth: 300, margin: "0 auto" }}
    >
      <div style={{ textAlign: "center", marginBottom: "24px" }}>
        <Title level={3} style={{ color: "#1668dc", margin: 0 }}>
          冲鸭沙箱注册
        </Title>
      </div>

      <Form.Item
        name="username"
        rules={[
          { required: true, message: "请输入用户名！" },
          { min: 3, message: "用户名至少3个字符！" },
        ]}
      >
        <Input
          prefix={<UserOutlined />}
          placeholder="用户名"
          size="large"
          disabled={loading}
        />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          { required: true, message: "请输入邮箱！" },
          { type: "email", message: "请输入有效的邮箱地址！" },
        ]}
      >
        <Input
          prefix={<MailOutlined />}
          placeholder="邮箱"
          size="large"
          disabled={loading}
        />
      </Form.Item>

      <Form.Item
        name="invitationCode"
        rules={[{ required: true, message: "请输入邀请码!" }]}
      >
        <Input
          prefix={<HighlightOutlined />}
          placeholder="邀请码"
          size="large"
          disabled={loading}
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          { required: true, message: "请输入密码！" },
          { min: 12, message: "密码至少12个字符!网站没验证码容易被爆破" },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="密码"
          size="large"
          disabled={loading}
        />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        dependencies={["password"]}
        rules={[
          { required: true, message: "请确认密码！" },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("两次输入的密码不一致！"));
            },
          }),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="确认密码"
          size="large"
          disabled={loading}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          size="large"
          loading={loading}
        >
          注册
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <ConfigProvider theme={darkTheme}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          background: "linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%)",
          padding: "20px",
        }}
      >
        <Card
          style={{
            width: 400,
            backgroundColor: "rgba(20, 20, 20, 0.95)",
            boxShadow: "0 8px 32px rgba(0, 0, 0, 0.3)",
            border: "1px solid rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(10px)",
          }}
        >
          <Tabs
            activeKey={activeTab}
            onChange={setActiveTab}
            centered
            items={[
              {
                key: "login",
                label: "登录",
                children: <LoginForm />,
              },
              {
                key: "register",
                label: "注册",
                children: <RegisterForm />,
              },
            ]}
          />
        </Card>
      </div>
    </ConfigProvider>
  );
};

export default AuthSystem;
