import React, { useState, useEffect } from "react";
import {
  Table,
  Card,
  Button,
  Modal,
  Form,
  InputNumber,
  message,
  Typography,
  Space,
  Tag,
  ConfigProvider,
  theme,
} from "antd";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { config } from "./config/index";

const { Title } = Typography;

interface InviteCode {
  ID: number;
  Code: string;
  Used: boolean;
  UsedBy: number | null;
  CreatedAt: string;
  ExpireAt: string | null;
}

const AdminInviteCodes: React.FC = () => {
  const [codes, setCodes] = useState<InviteCode[]>([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  // 获取Token
  const getAuthToken = () => localStorage.getItem("authToken");

  // 设置深色主题
  const darkTheme = {
    algorithm: theme.darkAlgorithm,
    token: {
      colorPrimary: "#1668dc",
      colorBgContainer: "#141414",
      colorBgElevated: "#1f1f1f",
      borderRadius: 6,
    },
  };

  // 获取邀请码列表
  const fetchInviteCodes = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${config.API_BASE_URL}/api/admin/invite-codes`,
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch invite codes");
      }

      const data = await response.json();
      setCodes(data);
    } catch (error) {
      message.error("获取邀请码列表失败");
    } finally {
      setLoading(false);
    }
  };

  // 创建新邀请码
  const createInviteCode = async (values: { expireDays?: number }) => {
    try {
      const response = await fetch(
        `${config.API_BASE_URL}/api/admin/invite-codes`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAuthToken()}`,
          },
          body: JSON.stringify({
            expireDays: values.expireDays,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create invite code");
      }

      const data = await response.json();
      message.success(`邀请码创建成功: ${data.code}`);
      setIsModalVisible(false);
      form.resetFields();
      fetchInviteCodes(); // 刷新列表
    } catch (error) {
      message.error("创建邀请码失败");
    }
  };

  useEffect(() => {
    fetchInviteCodes();
  }, []);

  // 表格列定义
  const columns = [
    {
      title: "ID",
      dataIndex: "ID",
      key: "id",
      width: 80,
    },
    {
      title: "邀请码",
      dataIndex: "Code",
      key: "code",
      width: 120,
    },
    {
      title: "状态",
      dataIndex: "Used",
      key: "used",
      width: 100,
      render: (used: boolean) => (
        <Tag color={used ? "red" : "green"}>{used ? "已使用" : "未使用"}</Tag>
      ),
    },
    {
      title: "使用者ID",
      dataIndex: "UsedBy",
      key: "usedBy",
      width: 100,
      render: (usedBy: number | null) => usedBy || "-",
    },
    {
      title: "创建时间",
      dataIndex: "CreatedAt",
      key: "createdAt",
      width: 180,
      render: (date: string) => new Date(date).toLocaleString(),
    },
    {
      title: "过期时间",
      dataIndex: "ExpireAt",
      key: "expireAt",
      width: 180,
      render: (date: string | null) =>
        date ? new Date(date).toLocaleString() : "永不过期",
    },
  ];

  return (
    <ConfigProvider theme={darkTheme}>
      <div
        style={{
          marginTop: "15px",
          height: "100%",
          overflow: "auto",
        }}
      >
        <Card
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Space
            direction="vertical"
            style={{ width: "100%", height: "100%" }}
            size="large"
          >
            <Space style={{ justifyContent: "space-between", display: "flex" }}>
              <Title level={3} style={{ margin: 0 }}>
                邀请码管理
              </Title>
              <Space>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setIsModalVisible(true)}
                >
                  生成邀请码
                </Button>
                <Button
                  icon={<ReloadOutlined />}
                  onClick={() => fetchInviteCodes()}
                >
                  刷新
                </Button>
              </Space>
            </Space>

            <Table
              style={{ flex: 1 }}
              columns={columns}
              dataSource={codes}
              rowKey="ID"
              loading={loading}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
              }}
              scroll={{ x: 1000, y: "calc(100vh - 280px)" }} // 减去头部、padding等高度
            />
          </Space>
        </Card>

        <Modal
          title="生成新邀请码"
          open={isModalVisible}
          onCancel={() => {
            setIsModalVisible(false);
            form.resetFields();
          }}
          onOk={() => form.submit()}
          confirmLoading={loading}
        >
          <Form form={form} onFinish={createInviteCode} layout="vertical">
            <Form.Item
              name="expireDays"
              label="过期天数（可选，留空表示永不过期）"
              tooltip="设置邀请码的有效期，单位为天"
            >
              <InputNumber
                min={1}
                max={365}
                style={{ width: "100%" }}
                placeholder="请输入过期天数"
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </ConfigProvider>
  );
};

export default AdminInviteCodes;
