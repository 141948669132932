import { config } from ".";

// src/utils/request.ts
interface RequestConfig extends RequestInit {
  data?: any;
  params?: Record<string, string>;
}

class Request {
  private baseURL: string;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
  }

  private getToken(): string | null {
    return localStorage.getItem("authToken");
  }

  private addAuthHeader(headers: HeadersInit = {}): HeadersInit {
    const token = this.getToken();
    if (token) {
      return {
        ...headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return headers;
  }

  private buildURL(endpoint: string, params?: Record<string, string>): string {
    const url = new URL(this.baseURL + endpoint);
    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        url.searchParams.append(key, value);
      });
    }
    return url.toString();
  }

  async request<T>(endpoint: string, config: RequestConfig = {}): Promise<T> {
    const { data, params, headers, ...restConfig } = config;

    // 构建请求配置
    const requestConfig: RequestInit = {
      ...restConfig,
      headers: this.addAuthHeader(headers),
    };

    // 如果是 POST/PUT/PATCH 请求且有 data，则添加 body
    if (data) {
      if (data instanceof FormData) {
        requestConfig.body = data;
      } else {
        requestConfig.headers = {
          "Content-Type": "application/json",
          ...requestConfig.headers,
        };
        requestConfig.body = JSON.stringify(data);
      }
    }

    const response = await fetch(
      this.buildURL(endpoint, params),
      requestConfig
    );

    // 统一错误处理
    if (!response.ok) {
      if (response.status === 401) {
        // 处理认证失败
        // 可以触发登出操作或跳转到登录页
        window.location.href = "/auth";
        throw new Error("认证失败，请重新登录");
      }
      throw new Error(await response.text());
    }

    return response.json();
  }

  // 封装常用请求方法
  get<T>(endpoint: string, config: RequestConfig = {}) {
    return this.request<T>(endpoint, { ...config, method: "GET" });
  }

  post<T>(endpoint: string, config: RequestConfig = {}) {
    return this.request<T>(endpoint, { ...config, method: "POST" });
  }

  put<T>(endpoint: string, config: RequestConfig = {}) {
    return this.request<T>(endpoint, { ...config, method: "PUT" });
  }

  delete<T>(endpoint: string, config: RequestConfig = {}) {
    return this.request<T>(endpoint, { ...config, method: "DELETE" });
  }

  // 专门用于处理文件上传的方法
  upload<T>(endpoint: string, file: File, config: RequestConfig = {}) {
    const formData = new FormData();
    formData.append("file", file);

    return this.request<T>(endpoint, {
      ...config,
      method: "POST",
      body: formData,
    });
  }
}

// 创建实例
export const request = new Request(config.API_BASE_URL || "");

// 导出类型
export type { RequestConfig };
