import React, { useState, useEffect } from 'react';
import { Card, Table, Button, message, Space, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import type { TableColumnsType } from 'antd';
import { config } from "./config/index";  // 添加配置文件导入

const { Title } = Typography;

interface FileData {
    name: string;
    path: string;
    size: string;
    uploadDate: string;
}

const FileDownload: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [downloadingId, setDownloadingId] = useState<string | null>(null);
    const [fileList, setFileList] = useState<FileData[]>([]);

    // 添加 getAuthToken 函数声明
    const getAuthToken = () => localStorage.getItem("authToken");

    const fetchFileList = async () => {
        try {
            const response = await fetch(`${config.API_BASE_URL}/api/files/list`, {  // 使用配置的基础URL
                headers: {
                    Authorization: `Bearer ${getAuthToken()}`  // 使用统一的token获取方法
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch file list');
            }

            const data = await response.json();
            setFileList(data);
        } catch (error) {
            console.error('Error fetching file list:', error);
            message.error('获取文件列表失败');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFileList();
    }, []);

    const columns: TableColumnsType<FileData> = [
        {
            title: '文件名称',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: '大小',
            dataIndex: 'size',
            key: 'size',
            width: 120,
        },
        {
            title: '上传日期',
            dataIndex: 'uploadDate',
            key: 'uploadDate',
            width: 120,
            sorter: (a, b) => a.uploadDate.localeCompare(b.uploadDate),
        },
        {
            title: '操作',
            key: 'action',
            width: 120,
            render: (_, record) => (
                <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={() => handleDownload(record)}
                    loading={downloadingId === record.name}
                    disabled={downloadingId !== null}
                >
                    下载
                </Button>
            ),
        },
    ];

    // 下载文件的方法也需要修改
    const handleDownload = async (record: FileData) => {
        try {
            setDownloadingId(record.name);

            const response = await fetch(
                `${config.API_BASE_URL}/api/files/download/${encodeURIComponent(record.path)}`,
                {
                    headers: {
                        Authorization: `Bearer ${getAuthToken()}`
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Download failed');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = record.name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);

            message.success(`开始下载: ${record.name}`);

        } catch (error) {
            console.error('Download error:', error);
            message.error('下载失败，请重试');
        } finally {
            setDownloadingId(null);
        }
    };

    return (
        <Card>
            <Space direction="vertical" style={{ width: '100%' }} size="middle">
                <Title level={4}>文件下载</Title>
                <Table
                    columns={columns}
                    dataSource={fileList}
                    rowKey="name"
                    pagination={{
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                    }}
                    scroll={{ x: 800 }}
                    loading={loading}
                />
            </Space>
        </Card>
    );
};

export default FileDownload;