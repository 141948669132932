import React, { useState, useEffect } from "react";
import {
  Table,
  Card,
  Button,
  Modal,
  message,
  Typography,
  Space,
  Tag,
  Popconfirm,
} from "antd";
import {
  ReloadOutlined,
  DeleteOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { config } from "./config/index";

const { Title } = Typography;

interface User {
  ID: number;
  Username: string;
  Email: string;
  Permission: number;
  Status: string;
  CreatedAt: string;
  UpdatedAt: string;
}

const AdminUsers: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);

  // 获取Token
  const getAuthToken = () => localStorage.getItem("authToken");

  // 获取用户列表
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${config.API_BASE_URL}/api/admin/users`, {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch users");
      }

      const data = await response.json();
      setUsers(data);
    } catch (error) {
      message.error("获取用户列表失败");
    } finally {
      setLoading(false);
    }
  };

  // 删除用户
  const handleDeleteUser = async (userId: number) => {
    try {
      const response = await fetch(
        `${config.API_BASE_URL}/api/admin/users/${userId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete user");
      }

      message.success("用户删除成功");
      fetchUsers(); // 刷新列表
    } catch (error) {
      message.error("删除用户失败");
    }
  };

  // 更新用户状态
  const handleUpdateUserStatus = async (userId: number, newStatus: string) => {
    try {
      const response = await fetch(
        `${config.API_BASE_URL}/api/admin/users/${userId}/status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAuthToken()}`,
          },
          body: JSON.stringify({ status: newStatus }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update user status");
      }

      message.success("用户状态更新成功");
      fetchUsers(); // 刷新列表
    } catch (error) {
      message.error("更新用户状态失败");
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // 表格列定义
  const columns = [
    {
      title: "ID",
      dataIndex: "ID",
      key: "id",
      width: 80,
    },
    {
      title: "用户名",
      dataIndex: "Username",
      key: "username",
      width: 120,
    },
    {
      title: "邮箱",
      dataIndex: "Email",
      key: "email",
      width: 200,
    },
    {
      title: "权限",
      dataIndex: "Permission",
      key: "permission",
      width: 100,
      render: (permission: number) => (
        <Tag color={permission === 1 ? "gold" : "blue"}>
          {permission === 1 ? "管理员" : "普通用户"}
        </Tag>
      ),
    },
    {
      title: "状态",
      dataIndex: "Status",
      key: "status",
      width: 100,
      render: (status: string) => (
        <Tag color={status === "active" ? "green" : "red"}>
          {status === "active" ? "正常" : "已冻结"}
        </Tag>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "CreatedAt",
      key: "createdAt",
      width: 180,
      render: (date: string) => new Date(date).toLocaleString(),
    },
    {
      title: "操作",
      key: "action",
      width: 200,
      render: (_: any, record: User) => (
        <Space size="middle">
          <Popconfirm
            title={`确定要${
              record.Status === "active" ? "冻结" : "解冻"
            }该用户吗？`}
            onConfirm={() =>
              handleUpdateUserStatus(
                record.ID,
                record.Status === "active" ? "frozen" : "active"
              )
            }
            okText="确定"
            cancelText="取消"
          >
            <Button
              type="text"
              icon={<StopOutlined />}
              danger={record.Status === "active"}
            >
              {record.Status === "active" ? "冻结" : "解冻"}
            </Button>
          </Popconfirm>
          <Popconfirm
            title="确定要删除该用户吗？"
            onConfirm={() => handleDeleteUser(record.ID)}
            okText="确定"
            cancelText="取消"
          >
            <Button type="text" danger icon={<DeleteOutlined />}>
              删除
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Card style={{ marginTop: "16px" }}>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <Space style={{ justifyContent: "space-between", display: "flex" }}>
          <Title level={3} style={{ margin: 0 }}>
            用户管理
          </Title>
          <Button icon={<ReloadOutlined />} onClick={() => fetchUsers()}>
            刷新
          </Button>
        </Space>

        <Table
          columns={columns}
          dataSource={users}
          rowKey="ID"
          loading={loading}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            showQuickJumper: true,
          }}
          scroll={{ x: 1200 }}
        />
      </Space>
    </Card>
  );
};

export default AdminUsers;
