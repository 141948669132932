import React from "react";
import { Timeline, Card, Space, Tag, Typography, theme } from "antd";
import {
  CodeOutlined,
  KeyOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

const { Text, Paragraph } = Typography;
interface RegistryOperationsProps {
  registryOperations?: any[];
}
const RegistryOperations: React.FC<RegistryOperationsProps> = ({
  registryOperations,
}) => {
  const { token } = theme.useToken();

  // 自定义卡片样式
  const cardStyle = {
    background: token.colorBgContainer,
    borderRadius: token.borderRadiusLG,
    marginBottom: 16,
  };

  // 自定义文字样式
  const textStyle = {
    color: token.colorTextSecondary,
    fontSize: token.fontSize,
  };

  // 格式化注册表操作类型
  const formatOperationType = (type: any) => {
    switch (type) {
      case "1":
        return "写入";
      case "2":
        return "删除";
      case "3":
        return "读取";
      default:
        return "未知操作";
    }
  };

  // 格式化注册表类型
  const formatRegistryType = (type: any) => {
    switch (type) {
      case "1":
        return "REG_SZ";
      case "2":
        return "REG_BINARY";
      case "3":
        return "REG_DWORD";
      case "4":
        return "REG_QWORD";
      case "5":
        return "REG_MULTI_SZ";
      case "6":
        return "REG_EXPAND_SZ";
      default:
        return "未知类型";
    }
  };

  return (
    <Timeline
      style={{ padding: "16px" }}
      items={registryOperations?.map((op: any, index) => ({
        color: token.colorPrimary,
        children: (
          <Card key={index} size="small" bordered={false} style={cardStyle}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              {/* 注册表路径 */}
              <Paragraph
                copyable
                ellipsis={{ rows: 2, expandable: true, symbol: "展开" }}
                style={textStyle}
              >
                <Text style={{ ...textStyle, fontWeight: "bold" }}>
                  <KeyOutlined /> 路径：
                </Text>
                {op.RegistryPath}
              </Paragraph>

              {/* 操作标签组 */}
              <Space wrap>
                <Tag icon={<CodeOutlined />} color="blue">
                  {formatOperationType(op.OperationType)}
                </Tag>
                <Tag color="purple">{formatRegistryType(op.RegistryType)}</Tag>
                <Tag icon={<ClockCircleOutlined />} color="cyan">
                  {new Date(op.CreateTime).toLocaleString("zh-CN")}
                </Tag>
              </Space>

              {/* 值名称和内容 */}
              {op.ValueName && (
                <div>
                  <Text style={{ ...textStyle, fontWeight: "bold" }}>
                    值名称：
                  </Text>
                  <Text style={textStyle}>{op.ValueName || "(默认)"}</Text>
                </div>
              )}

              {op.RegistryValue && (
                <div>
                  <Text style={{ ...textStyle, fontWeight: "bold" }}>
                    值内容：
                  </Text>
                  <Paragraph
                    copyable
                    ellipsis={{ rows: 2, expandable: true, symbol: "展开" }}
                    style={textStyle}
                  >
                    {op.RegistryValue}
                  </Paragraph>
                </div>
              )}
            </Space>
          </Card>
        ),
      }))}
    />
  );
};

export default RegistryOperations;
