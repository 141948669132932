import React from "react";
import { Layout } from "antd";
import AppHeader from "./AppHeader";

const { Content } = Layout;

interface BasicLayoutProps {
  children: React.ReactNode;
}

const BasicLayout: React.FC<BasicLayoutProps> = ({ children }) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <AppHeader />
      <Content
        style={{
          backgroundColor: "#141414", // 深色主题的背景色
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </Content>
    </Layout>
  );
};

export default BasicLayout;
