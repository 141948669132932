export interface AppConfig {
  API_BASE_URL: string;
}
export const config: AppConfig = {
  API_BASE_URL: "https://vt.key08.com",
  //API_BASE_URL: "http://localhost:1337",
};

export const API_BASE_URL = config.API_BASE_URL;

// 上传配置
export const UPLOAD_CONFIG = {
  MAX_FILE_SIZE: 1024 * 1024 * 150, // 150MB
  ALLOWED_TYPES: ["zip/*", "application/exe"], // 允许的文件类型
  MAX_UPLOAD_COUNT: 5, // 最大同时上传数量
};
