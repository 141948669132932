import React, { useState, useEffect } from "react";
import { Card, Table, Tag, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  CheckCircleOutlined,
  SyncOutlined,
  WarningOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { config } from "./config/index";

interface Task {
  ID: string;
  Sha1: string;
  Status: string;
  CreatedAt: string;
  CompletedAt: string | null;
  IncidentId: string;
  Metadata: {
    file_name: string;
    file_size: number;
  };
}

const HistoryList: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await fetch(
          `${config.API_BASE_URL}/api/task/my_list`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch tasks");
        }

        const data = await response.json();
        // 确保数据格式正确
        const formattedData = data.map((task: any) => ({
          ...task,
          Status: task.status || "pending", // 确保始终有状态值
          ID: task.id || task.ID,
          Sha1: task.sha1 || task.Sha1,
          CreatedAt: task.created_at || task.CreatedAt,
          CompletedAt: task.completed_at || task.CompletedAt,
          IncidentId: task.incident_id || task.IncidentId,
          Metadata: {
            file_name: task.metadata?.file_name || "",
            file_size: task.metadata?.file_size || 0,
          },
        }));
        setTasks(formattedData);
      } catch (error) {
        message.error("获取历史记录失败");
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, []);

  const formatDate = (dateString: string) => {
    if (!dateString) return "-";
    try {
      return new Date(dateString).toLocaleString("zh-CN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    } catch (error) {
      return "-";
    }
  };

  const getStatusTag = (status: string) => {
    // 确保status有值且为字符串
    const safeStatus = (status || "pending").toLowerCase();

    const statusConfig = {
      completed: {
        icon: <CheckCircleOutlined />,
        color: "success",
        text: "已完成",
      },
      pending: {
        icon: <ClockCircleOutlined />,
        color: "default",
        text: "等待中",
      },
      scanning: {
        icon: <SyncOutlined spin />,
        color: "processing",
        text: "扫描中",
      },
      failed: {
        icon: <WarningOutlined />,
        color: "error",
        text: "失败",
      },
    };

    const config =
      statusConfig[safeStatus as keyof typeof statusConfig] ||
      statusConfig.pending;

    return (
      <Tag icon={config.icon} color={config.color}>
        {config.text}
      </Tag>
    );
  };

  const formatFileSize = (bytes: number): string => {
    if (!bytes || isNaN(bytes)) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(Math.max(bytes, 1)) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };

  const columns = [
    {
      title: "文件名",
      dataIndex: ["Metadata", "file_name"],
      key: "fileName",
      render: (text: string) => (
        <span className="text-blue-500">{text || "-"}</span>
      ),
    },
    {
      title: "SHA1",
      dataIndex: "Sha1",
      key: "sha1",
      render: (text: string) => (
        <span className="font-mono text-sm">{text || "-"}</span>
      ),
    },
    {
      title: "文件大小",
      dataIndex: ["Metadata", "file_size"],
      key: "fileSize",
      render: (size: number) => formatFileSize(size),
    },
    {
      title: "状态",
      dataIndex: "Status",
      key: "status",
      render: (status: string) => getStatusTag(status),
    },
    {
      title: "威胁事件",
      dataIndex: "IncidentId",
      key: "incident",
      render: (incidentId: string) =>
        incidentId ? (
          <Tag color="red" icon={<WarningOutlined />}>
            有威胁
          </Tag>
        ) : (
          <Tag color="green" icon={<CheckCircleOutlined />}>
            无威胁
          </Tag>
        ),
    },
    {
      title: "提交时间",
      dataIndex: "CreatedAt",
      key: "createdAt",
      render: (text: string) => formatDate(text),
    },
    {
      title: "完成时间",
      dataIndex: "CompletedAt",
      key: "completedAt",
      render: (text: string | null) => formatDate(text || ""),
    },
    {
      title: "操作",
      key: "action",
      render: (_: any, record: Task) => (
        <Button
          type="link"
          onClick={() => navigate(`/analysis?taskId=${record.ID}`)}
        >
          查看详情
        </Button>
      ),
    },
  ];

  return (
    <Card
      title="历史提交记录"
      bordered={false}
      className="m-6"
      style={{ background: "rgba(0, 0, 0, 0.02)" }}
    >
      {tasks.length > 0 && (
        <Table
          columns={columns}
          dataSource={tasks}
          rowKey="ID"
          loading={loading}
          pagination={{
            defaultPageSize: 10,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `共 ${total} 条记录`,
          }}
        />
      )}
    </Card>
  );
};

export default HistoryList;
