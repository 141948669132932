import React from "react";
import {
  Tabs,
  Descriptions,
  Tag,
  Empty,
  Typography,
  Card,
  Timeline,
  Space,
  Badge,
  theme,
} from "antd";
import type { TabsProps } from "antd";
import {
  CloudOutlined,
  GlobalOutlined,
  HddOutlined,
  CodeOutlined,
  SafetyCertificateOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { OperationsData, ProcessInfo } from "./types";
import RegistryOperations from "./RegistryOperations";

const { Text, Paragraph } = Typography;

interface ProcessDetailProps {
  processInfo: ProcessInfo | undefined;
  operationsData: OperationsData | undefined;
}

const ProcessDetailPanel: React.FC<ProcessDetailProps> = ({
  processInfo,
  operationsData,
}) => {
  const formatTimestamp = (timestamp: number): string => {
    return new Date(timestamp * 1000).toLocaleString("zh-CN", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  const formatDiskType = (type: number): string => {
    switch (type) {
      case 1:
        return "DLL加载";
      case 2:
        return "文件读取";
      case 3:
        return "可执行文件写入";
      default:
        return "未知";
    }
  };
  const formatDnsType = (type: number) => {
    switch (type) {
      case 1:
        return "IOC标记域名";
      case 2:
        return "恶意域名";
      default:
        return "未知类型";
    }
  };

  const { token } = theme.useToken();

  // 自定义文字样式
  const textStyle = {
    color: token.colorTextSecondary,
    fontSize: token.fontSize,
  };
  // 自定义卡片样式
  const cardStyle = {
    background: token.colorBgContainer,
    borderRadius: token.borderRadiusLG,
    marginBottom: 16,
  };

  // 安全等级标签颜色映射
  const getLevelBadgeProps = (level: string) => {
    const levelNum = parseInt(level || "0");
    if (levelNum > 40) {
      return { status: "error" as const, text: "恶意" };
    } else if (levelNum >= 30) {
      return { status: "warning" as const, text: "可疑" };
    }
    return { status: "success" as const, text: "安全" };
  };

  const basicInfo = (
    <Card bordered={false} style={cardStyle}>
      <Descriptions
        column={2}
        size="small"
        labelStyle={{
          fontWeight: "bold",
          color: token.colorText,
          fontSize: token.fontSize,
        }}
        contentStyle={textStyle}
      >
        <Descriptions.Item label="进程名称" span={2}>
          <Text style={{ ...textStyle, fontWeight: "bold" }}>
            {processInfo?.p_name || "-"}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="完整路径" span={2}>
          <Paragraph
            copyable
            ellipsis={{ rows: 2, expandable: true, symbol: "展开" }}
            style={textStyle}
          >
            {processInfo?.p_path || "-"}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item label="命令行" span={2}>
          <Paragraph
            copyable
            ellipsis={{ rows: 2, expandable: true, symbol: "展开" }}
            style={textStyle}
          >
            {processInfo?.p_command_line || "-"}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item label="进程ID">
          <Text code style={textStyle}>
            {processInfo?.p_id || "-"}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="父进程ID">
          <Text code style={textStyle}>
            {processInfo?.pp_id || "-"}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="安全性">
          <Badge {...getLevelBadgeProps(processInfo?.p_level || "0")} />
        </Descriptions.Item>
        <Descriptions.Item label="完整性级别">
          <Tag
            color={
              processInfo?.p_integrity_level === "High" ? "orange" : "blue"
            }
          >
            {processInfo?.p_integrity_level || "-"}
          </Tag>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );

  const networkInfo = operationsData?.network_operation ? (
    <Timeline
      style={{ padding: "16px" }}
      items={operationsData.network_operation.map((net, index) => ({
        color: net.Risk === "critical" ? token.colorError : token.colorPrimary,
        children: (
          <Card
            key={index}
            size="small"
            bordered={false}
            style={{
              ...cardStyle,
              background:
                net.Risk === "critical"
                  ? token.colorErrorBg
                  : token.colorBgContainer,
            }}
          >
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Space wrap>
                {net.RemoteIp && (
                  <Tag icon={<GlobalOutlined />} color="blue">
                    远程IP: {net.RemoteIp}
                  </Tag>
                )}
                {net.LocalIp && (
                  <Tag icon={<GlobalOutlined />} color="green">
                    本地IP: {net.LocalIp}
                  </Tag>
                )}
                {net.Port && <Tag color="purple">本地端口: {net.Port}</Tag>}
                {/* 其他网络信息标签 */}
              </Space>
              <Text style={textStyle}>
                时间:{" "}
                {net.CreateTime &&
                  new Date(net.CreateTime).toLocaleString("zh-CN")}
              </Text>
            </Space>
          </Card>
        ),
      }))}
    />
  ) : (
    <Empty
      description={<Text style={textStyle}>暂无网络操作数据</Text>}
      style={{ margin: "32px 0" }}
    />
  );

  const dnsInfo = operationsData?.dns_request ? (
    <Timeline
      items={operationsData.dns_request.map((dns, index) => ({
        color: dns.Risk === "critical" ? "red" : "blue",
        children: (
          <Card
            key={index}
            size="small"
            bordered={false}
            style={{ marginBottom: 16 }}
            className={
              dns.Risk === "critical"
                ? "site-card-border-less-wrapper-danger"
                : ""
            }
          >
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Space wrap>
                <Tag
                  icon={<GlobalOutlined />}
                  color={dns.Risk === "critical" ? "error" : "blue"}
                >
                  {dns.Domain}
                </Tag>
                {dns.Risk && <Tag color="red">{dns.MaliciousType}</Tag>}
                {dns.DNSType && <Tag>{formatDnsType(dns.DNSType)}</Tag>}
              </Space>
              {dns.CreateTime && (
                <Text type="secondary">时间: {dns.CreateTime}</Text>
              )}
            </Space>
          </Card>
        ),
      }))}
    />
  ) : (
    <Empty description="暂无DNS请求数据" />
  );

  const diskInfo = operationsData?.disk_operation ? (
    <Timeline
      items={operationsData.disk_operation.map((disk, index) => ({
        color: "blue",
        children: (
          <Card
            key={index}
            size="small"
            bordered={false}
            style={{ marginBottom: 16 }}
          >
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Paragraph copyable ellipsis={{ rows: 2, expandable: true }}>
                {disk.DiskPath1}
              </Paragraph>
              <Space wrap>
                {disk.DiskType && (
                  <Tag color="blue">{formatDiskType(disk.DiskType)}</Tag>
                )}
                {disk.Md5 && (
                  <Tag color="cyan" icon={<SafetyCertificateOutlined />}>
                    MD5: {disk.Md5}
                  </Tag>
                )}
                {disk.ProductName && <Tag>{disk.ProductName}</Tag>}
              </Space>
              {disk.CreateTime && (
                <Text type="secondary">时间: {disk.CreateTime}</Text>
              )}
            </Space>
          </Card>
        ),
      }))}
    />
  ) : (
    <Empty description="暂无磁盘操作数据" />
  );
  const memInfo = operationsData?.memory_operation ? (
    <Timeline
      items={operationsData.memory_operation.map((mem, index) => ({
        color: "blue",
        children: (
          <Card
            key={index}
            size="small"
            bordered={false}
            style={{ marginBottom: 16 }}
          >
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Space wrap>
                {mem.Operation && (
                  <Tag icon={<DatabaseOutlined />} color="blue">
                    操作: {mem.Operation}
                  </Tag>
                )}
                {mem.Object && <Tag color="purple">目标: {mem.Object}</Tag>}
              </Space>
              {mem.Threadstart && (
                <Space>
                  <Tag icon={<CodeOutlined />} color="cyan">
                    线程起始地址: {mem.Threadstart}
                  </Tag>
                </Space>
              )}
              {mem.Callersymbol && (
                <Text type="secondary">调用符号: {mem.Callersymbol}</Text>
              )}
              {mem.Callstackjson && (
                <Paragraph
                  ellipsis={{ rows: 2, expandable: true, symbol: "展开调用栈" }}
                >
                  {JSON.parse(mem.Callstackjson).map(
                    (call: any, idx: number) => (
                      <div
                        key={idx}
                        style={{ fontSize: "12px", color: "#666" }}
                      >
                        {call.symbol}{" "}
                        {call.export_name ? `(${call.export_name})` : ""}
                      </div>
                    )
                  )}
                </Paragraph>
              )}
              {mem.CreateTime && (
                <Text type="secondary">时间: {mem.CreateTime}</Text>
              )}
            </Space>
          </Card>
        ),
      }))}
    />
  ) : (
    <Empty description="暂无内存数据" />
  );
  const items: TabsProps["items"] = [
    {
      key: "basic",
      label: (
        <span>
          <SafetyCertificateOutlined />
          基本信息
        </span>
      ),
      children: basicInfo,
    },
    {
      key: "network",
      label: (
        <span>
          <CloudOutlined />
          网络操作({operationsData?.corpus_size.network_operation || 0})
        </span>
      ),
      children: networkInfo,
      disabled: !operationsData?.corpus_size.network_operation,
    },
    {
      key: "disk",
      label: (
        <span>
          <HddOutlined />
          磁盘操作({operationsData?.corpus_size.disk_operation || 0})
        </span>
      ),
      children: diskInfo,
      disabled: !operationsData?.corpus_size.disk_operation,
    },
    {
      key: "dns",
      label: (
        <span>
          <GlobalOutlined />
          DNS请求({operationsData?.corpus_size.dns_request || 0})
        </span>
      ),
      children: dnsInfo,
      disabled: !operationsData?.corpus_size.dns_request,
    },
    {
      key: "memory",
      label: (
        <span>
          <DatabaseOutlined />
          内存操作({operationsData?.corpus_size.memory_operation || 0})
        </span>
      ),
      disabled: !operationsData?.corpus_size.memory_operation,
      children: memInfo,
    },
    {
      key: "registry",
      label: (
        <span>
          <CodeOutlined />
          注册表操作({operationsData?.corpus_size.registry_operation || 0})
        </span>
      ),
      disabled: !operationsData?.corpus_size.registry_operation,
      children: (
        <RegistryOperations
          registryOperations={operationsData?.registry_operation}
        />
      ),
    },
  ];

  return (
    <Card bordered={false}>
      <Tabs
        items={items}
        type="card"
        defaultActiveKey="basic"
        size="large"
        animated={{ inkBar: true, tabPane: true }}
      />
    </Card>
  );
};

export default ProcessDetailPanel;
