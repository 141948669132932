import React from "react";
import {
  Card,
  Tag,
  Typography,
  Space,
  Descriptions,
  Badge,
  Alert,
  theme,
} from "antd";
import {
  SafetyCertificateOutlined,
  WarningOutlined,
  ExclamationCircleOutlined,
  ArrowRightOutlined,
  FileTextOutlined,
  CodeOutlined,
} from "@ant-design/icons";

const { Text, Title } = Typography;

interface IOCData {
  file: string;
  is_allow_ioc: number;
  sha1: string;
}

interface IOAData {
  ioa_id: string;
  log_id: string;
  rpc_process: string;
  key: string;
  p_uuid: string;
  t_id: string;
  ta_id: string;
  t_name: string;
  ta_name: string;
  name: string;
  description: string;
  threat_level: string;
  ioc_data: IOCData[];
  command_line: string;
  file_name: string;
  hostname: string;
  script_content: string;
  have_ioa_operation: boolean;
  malicious_data: any;
}

interface IOADetailsProps {
  ioaData: IOAData[];
}

type SeverityConfig = {
  icon: React.ReactNode;
  color: "error" | "warning" | "info" | "success";
  text: string;
  badge: "error" | "warning" | "success" | "processing" | "default";
};

const IOADetailsPanel: React.FC<IOADetailsProps> = ({ ioaData }) => {
  const { token } = theme.useToken();

  if (!ioaData || ioaData.length === 0) {
    return null;
  }

  const getSeverityData = (level: string): SeverityConfig => {
    const baseIconStyle = { fontSize: 20 };

    const configs: Record<string, SeverityConfig> = {
      "4": {
        icon: (
          <ExclamationCircleOutlined
            style={{ ...baseIconStyle, color: token.colorError }}
          />
        ),
        color: "error",
        text: "严重",
        badge: "error",
      },
      "3": {
        icon: (
          <WarningOutlined
            style={{ ...baseIconStyle, color: token.colorWarning }}
          />
        ),
        color: "warning",
        text: "高危",
        badge: "warning",
      },
      "2": {
        icon: (
          <WarningOutlined
            style={{ ...baseIconStyle, color: token.colorWarning }}
          />
        ),
        color: "warning",
        text: "中危",
        badge: "warning",
      },
      "1": {
        icon: (
          <SafetyCertificateOutlined
            style={{ ...baseIconStyle, color: token.colorInfo }}
          />
        ),
        color: "info",
        text: "低危",
        badge: "processing",
      },
    };

    return configs[level] || configs["1"];
  };

  return (
    <div style={{ marginTop: 24 }}>
      {ioaData.map((ioa) => {
        const severityData = getSeverityData(ioa.threat_level);

        return (
          <Card
            key={ioa.ioa_id}
            style={{ marginBottom: 16 }}
            bodyStyle={{ padding: 24 }}
          >
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              <div>
                <Badge
                  status={severityData.badge}
                  text={
                    <Space size="middle">
                      <Title level={4} style={{ margin: 0 }}>
                        {ioa.name}
                      </Title>
                      <Tag icon={severityData.icon} color={severityData.color}>
                        {severityData.text}
                      </Tag>
                    </Space>
                  }
                />
              </div>

              <Alert
                message={
                  <Space size="large">
                    <Tag color="blue">
                      {ioa.ta_name} ({ioa.ta_id})
                    </Tag>
                    <ArrowRightOutlined />
                    <Tag color="cyan">
                      {ioa.t_name} ({ioa.t_id})
                    </Tag>
                  </Space>
                }
                description={ioa.description}
                type={severityData.color}
                showIcon
              />

              <Descriptions column={1} bordered size="small">
                {ioa.command_line && (
                  <Descriptions.Item
                    label={
                      <Space>
                        <CodeOutlined />
                        执行命令
                      </Space>
                    }
                  >
                    <Text code copyable style={{ width: "100%" }}>
                      {ioa.command_line}
                    </Text>
                  </Descriptions.Item>
                )}

                {ioa.ioc_data.length > 0 && (
                  <Descriptions.Item
                    label={
                      <Space>
                        <FileTextOutlined />
                        相关文件
                      </Space>
                    }
                  >
                    <Space direction="vertical" style={{ width: "100%" }}>
                      {ioa.ioc_data.map((ioc, idx) => (
                        <Card size="small" key={idx}>
                          <Text strong>{ioc.file}</Text>
                          <br />
                          <Text type="secondary" copyable>
                            SHA1: {ioc.sha1}
                          </Text>
                        </Card>
                      ))}
                    </Space>
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Space>
          </Card>
        );
      })}
    </div>
  );
};

export default IOADetailsPanel;
