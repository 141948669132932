import React, { useState, useEffect } from "react";
import {
  Card,
  Progress,
  Descriptions,
  Tag,
  Timeline,
  Spin,
  Alert,
  Table,
  Space,
  TableProps,
  message,
  Button,
} from "antd";
import {
  CheckCircleOutlined,
  SyncOutlined,
  WarningOutlined,
  ExclamationOutlined,
} from "@ant-design/icons";
import "./Analysis.css";
import { config } from "./config/index";

import { useLocation, useNavigate } from "react-router-dom";
import ProcessTreeComponent from "./ProcessTreeComponent";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import {
  TaskDetails,
  AnalysisData,
  Detection,
  DetectionResponse,
  Incident,
  IncidentResponse,
  ProcessTree,
  ProcessTreeResponse,
  ProcessInfo,
  OperationsData,
} from "./types";
import AttackMatrix from "./AttackMatrix";

type ThreatLevel = "unknown" | "safe" | "suspicious" | "dangerous";
type AnalysisStatus = "scanning" | "completed" | "warning";
const getAuthToken = () => localStorage.getItem("authToken");

function Analysis() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [detectionLoading, setDetectionLoading] = useState(false);
  const [tableData, setTableData] = useState<IOATable_DataType[]>([]);
  const [incidentData, setIncidentData] = useState<Incident[]>([
    {
      id: "",
      incident_id: "",
      incident_name: "",
      score: 0,
      client_id: "",
      start_time: 0,
      end_time: 0,
      status: 0,
      host_name: "",
      host_status: "",
      operating_system: "",
      ttp: {
        target: "",
        technique: "",
        course: "",
      },
    },
  ]);
  const [selectedProcessTree, setSelectedProcessTree] =
    useState<ProcessTree | null>(null);
  const [currentDetectionId, setCurrentDetectionId] = useState<string>("");
  const [selectedProcess, setSelectedProcess] = useState<ProcessInfo>();
  const [processIOAData, setProcessIOAData] = useState<any>(null);
  const queryParams = new URLSearchParams(location.search);

  // 将detection数据转换为表格数据的函数
  const mapDetectionToTableData = (
    detections: Detection[]
  ): IOATable_DataType[] => {
    return detections.map((detection, index) => {
      // 从detection中提取主要IOA信息
      const mainIoa = detection.main_ioa;

      // 根据threat_level映射告警级别
      const getAlertLevel = (level: number): string => {
        switch (level) {
          case 1:
            return "低危";
          case 2:
            return "中危";
          case 3:
            return "高危";
          case 4:
            return "严重";
        }
        if (level >= 7) return "高危";
        if (level >= 4) return "中危";
        return "低危";
      };

      // 从ta_names和t_names数组中获取第一个元素作为展示
      const taName = detection.ta_names[0] || "未知";
      const tName = detection.t_names[0] || "未知";

      // 生成标签
      const generateTags = (detection: Detection): string[] => {
        console.log("detection", detection);
        const tags: string[] = [];
        for (const t of detection.ta_statistics) {
          tags.push(t.ta_name);
        }

        // 可以根据其他特征添加更多标签
        return tags.length > 0 ? tags : ["未分类"];
      };

      return {
        key: String(index), // React列表需要唯一key
        alertLevel: getAlertLevel(mainIoa.threat_level),
        tirgetProcess: mainIoa.p_name,
        ta_names: taName,
        t_names: tName,
        tags: generateTags(detection),
        detection_id: detection.detection_id, // 添加 detection_id
      };
    });
  };
  const [analysisData, setAnalysisData] = useState<AnalysisData>({
    fileName: "",
    fileSize: "",
    fileSha1: "",
    status: "scanning",
    progress: 0,
    threatLevel: "unknown",
    details: [{ time: "0:00:00", action: "静态分析", result: "开始中" }],
  });
  useEffect(() => {
    const taskId = queryParams.get("taskId") || "";
    if (taskId === "") {
      navigate("/");
      return;
    }
    const token = localStorage.getItem("userInfo");
    if (!token) {
      navigate("/auth");
    }
    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return date.toLocaleString("zh-CN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    };

    const formatFileSize = (bytes: number): string => {
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes === 0) return "0 Byte";
      const i = parseInt(
        Math.floor(Math.log(bytes) / Math.log(1024)).toString()
      );
      return (
        Math.round((bytes / Math.pow(1024, i)) * 100) / 100 + " " + sizes[i]
      );
    };

    const getProgressFromStatus = (status: string): number => {
      switch (status) {
        case "completed":
          return 100;
        case "failed":
          return 100;
        case "assigned":
          return 20;
        case "pending":
          return 10;
        default:
          return 0;
      }
    };

    // 添加拉取 detection 信息的函数
    const fetchDetectionInfo = async () => {
      const response = await fetch(
        `${config.API_BASE_URL}/api/task/${taskId}/detection?page=1&limit=20`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAuthToken()}`,
          },
        }
      );
      console.log("fetchDetectionInfo response", response);
      if (response.ok) {
        const responseData: DetectionResponse = await response.json();

        if (responseData.data) {
          console.log("responseData", responseData);
          const mappedData = mapDetectionToTableData(responseData.data);
          console.log("mappedData", mappedData);
          setTableData(mappedData);
          // 如果有数据，更新 incidentData state
          console.log("mappedData.length", mappedData.length);
          if (mappedData.length > 0) {
            setAnalysisData((prevData) => ({
              ...prevData,
              threatLevel: "dangerous",
            }));
            //setIncidentData(responseData.data[0]);
          } else if (mappedData.length === 2) {
            setAnalysisData((prevData) => ({
              ...prevData,
              threatLevel: "suspicious",
            }));
            //setIncidentData(responseData.data[0]);
          } else if (mappedData.length <= 0) {
            setAnalysisData((prevData) => ({
              ...prevData,
              threatLevel: "safe",
            }));
            //setIncidentData(responseData.data[0]);
          }
        }
      }

      setDetectionLoading(false);
    };
    const fetchIncidentInfo = async () => {
      try {
        const response = await fetch(
          `${config.API_BASE_URL}/api/task/${taskId}/incident?page=1&limit=20`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getAuthToken()}`,
            },
          }
        );

        if (response.ok) {
          const responseData: IncidentResponse = await response.json();
          if (responseData.data && responseData.data.length > 0) {
            setIncidentData(responseData.data);
            console.log("更新incident");
          }
        }
      } catch (error) {
        console.error("Failed to fetch incident data:", error);
      }
    };
    const fetchTaskDetails = async () => {
      try {
        const response = await fetch(
          `${config.API_BASE_URL}/api/task/${taskId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getAuthToken()}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Task not found");
        }

        const data = await response.json();
        setAnalysisData({
          fileName: data.metadata.file_name,
          fileSize: formatFileSize(data.metadata.file_size),
          fileSha1: data.sha1,
          status: data.status,
          progress: getProgressFromStatus(data.status),
          threatLevel: "unknown",
          details: data.events.map((event: { time: string; message: any }) => ({
            time: formatDate(event.time),
            action: "状态更新",
            result: event.message,
          })),
        });
        await fetchDetectionInfo();
        fetchIncidentInfo();
        //setTaskDetails(data);
      } catch (error) {
        //navigate("/404");
      } finally {
        setLoading(false);
      }
    };
    setDetectionLoading(true);
    fetchTaskDetails();
  }, [location, navigate]);
  const getThreatTag = (level: string) => {
    const tagMap = {
      unknown: (
        <Tag icon={<CheckCircleOutlined />} color="warning">
          未知
        </Tag>
      ),
      safe: (
        <Tag icon={<CheckCircleOutlined />} color="success">
          安全
        </Tag>
      ),
      suspicious: (
        <Tag icon={<WarningOutlined />} color="warning">
          可疑
        </Tag>
      ),
      dangerous: (
        <Tag icon={<WarningOutlined />} color="error">
          危险
        </Tag>
      ),
      scanning: (
        <Tag icon={<SyncOutlined spin />} color="processing">
          扫描中
        </Tag>
      ),
    };
    return tagMap[level as keyof typeof tagMap] || tagMap.scanning;
  };
  // 修改获取进程树的函数
  const handleViewProcessTree = async (record: IOATable_DataType) => {
    const queryParams = new URLSearchParams(location.search);
    const taskId = queryParams.get("taskId");

    try {
      const response = await fetch(
        `${config.API_BASE_URL}/api/task/${taskId}/process/tree?detection_id=${record.detection_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAuthToken()}`,
          },
        }
      );

      if (response.ok) {
        const result: ProcessTreeResponse = await response.json();

        // 通过正确的路径访问进程树数据
        if (result.data?.data?.tree && result.data.data.tree.length > 0) {
          setSelectedProcessTree(result.data.data.tree[0]);
          setCurrentDetectionId(result.detection_id);
        } else {
          console.log("No process tree data available:", result);
          message.info("该进程暂无进程树数据");
        }
      }
    } catch (error) {
      console.error("Failed to fetch process tree:", error);
      message.error("获取进程树失败");
    }
  };
  const [operationsData, setOperationsData] = useState<
    OperationsData | undefined
  >(undefined);
  const fetchProcessOperations = async (
    taskId: string,
    pUuid: string,
    process: ProcessTree
  ) => {
    if (!taskId || !pUuid) return;

    setLoading(true);
    try {
      // 构建查询参数
      const params = {
        start_time: process.start_time,
        end_time: process.end_time,
      };

      const queryString = Object.entries(params)
        .filter(([_, value]) => value !== undefined)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

      // 发起请求
      const response = await fetch(
        `${config.API_BASE_URL}/api/task/${taskId}/process/${pUuid}/files${
          queryString ? `?${queryString}` : ""
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAuthToken()}`,
          },
        }
      );
      console.log("process operations Response:", response);

      if (!response.ok) {
        throw new Error("Failed to fetch process operations");
      }

      const data = await response.json();
      if (data.error === 0 && data.data) {
        setOperationsData(data.data);
      } else {
        throw new Error(data.message || "Failed to fetch process operations");
      }
    } catch (error) {
      console.error("Error fetching process operations:", error);
      message.error("获取进程操作数据失败");
    } finally {
      setLoading(false);
    }
  };
  // 同样修改获取进程详情的函数，添加 detection_id
  const handleProcessClick = async (process: ProcessTree) => {
    const queryParams = new URLSearchParams(location.search);
    const taskId = queryParams.get("taskId");

    try {
      // 使用存储的 currentDetectionId
      const response = await fetch(
        `${config.API_BASE_URL}/api/task/${taskId}/process/${process.p_uuid}/info?pname=${process.p_name}&pid=${process.p_id}&detection_id=${currentDetectionId}&start_time=${process.start_time}&end_time=${process.start_time}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAuthToken()}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSelectedProcess(data.data.data.process_info);
        console.log("Process Clicked:", data.data.data.process_info);
      }

      // 同时获取 IOA 数据
      const ioaResponse = await fetch(
        `${config.API_BASE_URL}/api/task/${taskId}/process/${process.p_uuid}/ioa?detection_id=${currentDetectionId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAuthToken()}`,
          },
        }
      );

      if (ioaResponse.ok) {
        const ioaData = await ioaResponse.json();
        setProcessIOAData(ioaData);
        if (taskId != null) {
          fetchProcessOperations(taskId, process.p_uuid, process);
        }
        // 可以添加一个状态来存储和显示 IOA 数据
        // setProcessIOA(ioaData.data);
      }
    } catch (error) {
      console.error("Failed to fetch process info:", error);
    }
  };
  interface IOATable_DataType {
    alertLevel: string;
    tirgetProcess: string;
    ta_names: string;
    t_names: string;
    tags: string[];
    detection_id: string; // 添加 detection_id
  }

  const IOATableData_Columns: TableProps<IOATable_DataType>["columns"] = [
    {
      title: "告警级别",
      dataIndex: "alertLevel",
      key: "alertLevel",
      render: (_, record) => (
        <>
          <Tag color="red">{record.alertLevel}</Tag>
        </>
      ),
    },
    {
      title: "触发进程",
      dataIndex: "tirgetProcess",
      key: "age",
    },
    {
      title: "战术",
      dataIndex: "ta_names",
      key: "ta_names",
    },
    {
      title: "技术",
      dataIndex: "t_names",
      key: "t_names",
    },
    {
      title: "标签",
      dataIndex: "tags",
      key: "tags",
      render: (_, { tags }: { tags: string[] }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 5 ? "geekblue" : "green";
            if (tag === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    Table.EXPAND_COLUMN,
  ];
  console.log("incidentData[0].ttp", incidentData[0]);
  const targetTags = incidentData[0].ttp.target
    .split("、")
    .map((tag) => tag.trim());
  const techniqueTags = incidentData[0].ttp.technique
    .split("、")
    .map((tag) => tag.trim());

  return (
    <Card title="文件分析结果" bordered={false} className="analysis-container">
      <div style={{ display: "flex", gap: "24px" }}>
        {/* 左侧基本信息 */}
        <Card style={{ flex: 1 }} className="overlay-color">
          <Descriptions title="基本信息" column={1}>
            <Descriptions.Item label="文件名">
              {analysisData.fileName}
            </Descriptions.Item>
            <Descriptions.Item label="文件大小">
              {analysisData.fileSize}
            </Descriptions.Item>
            <Descriptions.Item label="文件Sha1">
              {analysisData.fileSha1}
            </Descriptions.Item>
            <Descriptions.Item label="威胁等级">
              {getThreatTag(analysisData.threatLevel)}
            </Descriptions.Item>
          </Descriptions>
          <div style={{ marginTop: "24px" }}>
            <Progress
              percent={analysisData.progress}
              status={
                analysisData.status === "completed" ? "success" : "active"
              }
            />
          </div>
        </Card>

        {/* 右侧分析详情 */}
        <Card style={{ flex: 2 }} title="分析时间线" className="overlay-color">
          {analysisData.status === "scanning" ? (
            <div style={{ textAlign: "center", padding: "20px" }}>
              <Spin size="large" />
              <div style={{ marginTop: "16px" }}>正在进行深度分析...</div>
            </div>
          ) : (
            <Timeline mode="left">
              {analysisData.details.map((item, index) => (
                <Timeline.Item key={index} label={item.time}>
                  {item.action}: {item.result}
                </Timeline.Item>
              ))}
            </Timeline>
          )}
        </Card>
      </div>

      {analysisData.status === "completed" ? (
        <Alert
          message="分析完成"
          description="文件分析已完成，请查看详细报告。(注意结果只保留30天!)"
          type="success"
          showIcon
          style={{ marginTop: "24px" }}
        />
      ) : (
        <Alert
          message="还在分析中..."
          description="可能会产生告警,但是远不止于此..."
          type="info"
          showIcon
          style={{ marginTop: "24px" }}
        />
      )}

      {incidentData[0].incident_id.length > 0 && (
        <div>
          <Card
            style={{ flex: 2, marginTop: "24px" }}
            title="该样本产生了一个威胁事件"
            className="overlay-color"
          >
            <Descriptions column={1}>
              <Descriptions.Item label="事件名称">
                {incidentData[0].incident_name}
              </Descriptions.Item>
              <Descriptions.Item label="威胁得分">
                <Tag color={incidentData[0].score > 4 ? "red" : "orange"}>
                  {incidentData[0].score.toFixed(1)}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label="威胁目标">
                {targetTags.map((tag) => (
                  <Tag color="blue" key={tag}>
                    {tag}
                  </Tag>
                ))}
              </Descriptions.Item>
              <Descriptions.Item label="技术手段">
                {techniqueTags.map((tag) => (
                  <Tag color="green" key={tag}>
                    {tag}
                  </Tag>
                ))}
              </Descriptions.Item>
            </Descriptions>
          </Card>
          <AttackMatrix taskID={queryParams.get("taskId")}></AttackMatrix>
        </div>
      )}

      <Card
        style={{ flex: 2, marginTop: "24px" }}
        title="该样本产生的告警"
        loading={detectionLoading}
      >
        <Table<IOATable_DataType>
          columns={IOATableData_Columns}
          dataSource={tableData}
          expandable={{
            expandedRowRender: (record) => (
              <div className="p-4">
                {selectedProcessTree && (
                  <ProcessTreeComponent
                    node={selectedProcessTree}
                    onProcessClick={handleProcessClick}
                    selectedProcessId={selectedProcess?.p_uuid}
                    selectedProcess={selectedProcess}
                    ioaData={processIOAData} // Pass the IOA data
                    taskId={queryParams.get("taskId") || undefined}
                    operationsData={operationsData || undefined}
                  />
                )}
              </div>
            ),
            columnTitle: "操作",
            columnWidth: 120,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <Button
                  type="dashed"
                  onClick={(e) => {
                    onExpand(record, e);
                    setSelectedProcessTree(null);
                  }}
                >
                  收起详情
                </Button>
              ) : (
                <Button
                  type="dashed"
                  onClick={(e) => {
                    onExpand(record, e);
                    handleViewProcessTree(record);
                  }}
                  disabled={selectedProcessTree !== null}
                >
                  查看详情
                </Button>
              ),
          }}
        />
      </Card>
    </Card>
  );
}

export default Analysis;
