import React from "react";
import { ConfigProvider, theme, Space } from "antd";
import AdminInviteCodes from "./AdminInviteCodes";
import AdminUsers from "./AdminUsers";
import AdminSettings from "./AdminSettings";
import AdminDownloadMalware from "./AdminDownloadMalware";

const AdminPage: React.FC = () => {
  // 设置深色主题
  const darkTheme = {
    algorithm: theme.darkAlgorithm,
    token: {
      colorPrimary: "#1668dc",
      colorBgContainer: "#141414",
      colorBgElevated: "#1f1f1f",
      borderRadius: 6,
    },
  };

  return (
    <ConfigProvider theme={darkTheme}>
      <div
        style={{
          marginTop: "80px",
          padding: "0 16px",
          height: "100%",
          overflow: "auto",
        }}
      >
        <Space
          direction="vertical"
          size="middle"
          style={{
            width: "100%",
            marginBottom: "16px",
          }}
        >
          <AdminInviteCodes />
          <AdminUsers />
          <AdminDownloadMalware />
          <AdminSettings />
        </Space>
      </div>
    </ConfigProvider>
  );
};

export default AdminPage;
