import React, { useEffect, useState } from "react";
import { Card, Tag, Row, Col, Typography, Spin, message } from "antd";
import type { CSSProperties } from "react";
import { config } from "./config";

const { Title, Text } = Typography;

interface AttackMatrixProps {
  taskID: string | null; // 添加rmClientId作为props
}

interface Technique {
  ta_id: string;
  t_id: string;
  t_name: string;
  risk_level: string;
  sub_t_data: SubTechnique[];
}

interface SubTechnique {
  ta_id: string;
  t_id: string;
  risk_level: string;
  t_sub_id: string;
  t_sub_name: string;
}
const getAuthToken = () => localStorage.getItem("authToken");

const AttackMatrix: React.FC<AttackMatrixProps> = ({ taskID: taskId }) => {
  const [loading, setLoading] = useState(false);
  const [techniques, setTechniques] = useState<Technique[]>([]);

  // ATT&CK 战术名称映射
  const tactics = [
    { id: "TA0043", name: "初始访问" },
    { id: "TA0002", name: "执行" },
    { id: "TA0003", name: "权限维持" },
    { id: "TA0004", name: "权限提升" },
    { id: "TA0005", name: "防御规避" },
    { id: "TA0006", name: "凭据访问" },
    { id: "TA0007", name: "发现" },
    { id: "TA0008", name: "横向移动" },
    { id: "TA0009", name: "收集" },
    { id: "TA0011", name: "命令与控制" },
    { id: "TA0010", name: "数据窃取" },
    { id: "TA0040", name: "影响" },
  ];

  // 获取ATT&CK数据
  useEffect(() => {
    const fetchAttackData = async () => {
      if (!taskId) return;

      setLoading(true);
      try {
        const response = await fetch(
          `${config.API_BASE_URL}/api/task/${taskId}/incident/attck`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getAuthToken()}`,
            },
          }
        );
        console.log("att*ck", response);
        if (!response.ok) {
          throw new Error("Failed to fetch attack data");
        }
        const result = await response.json();
        const techniquesData = result.data?.data || [];

        setTechniques(techniquesData);
      } catch (error) {
        console.error("Error fetching attack data:", error);
        message.error("获取ATT&CK数据失败");
      } finally {
        setLoading(false);
      }
    };

    fetchAttackData();
  }, [taskId]);

  // 获取风险等级对应的颜色
  const getRiskColor = (level: string): string => {
    switch (level) {
      case "3":
        return "#ff4d4f";
      case "2":
        return "#faad14";
      case "1":
        return "#1890ff";
      default:
        return "#d9d9d9";
    }
  };

  const getRiskText = (level: string): string => {
    switch (level) {
      case "3":
        return "高危";
      case "2":
        return "中危";
      case "1":
        return "低危";
      default:
        return "信息";
    }
  };

  const matrixStyle: CSSProperties = {
    overflowX: "auto",
    background: "#141414",
    padding: "12px",
  };

  const columnStyle: CSSProperties = {
    minWidth: "200px",
    borderRight: "1px solid #303030",
    height: "100%",
    padding: "8px",
  };

  const tacticHeaderStyle: CSSProperties = {
    background: "#1f1f1f",
    padding: "12px",
    textAlign: "center",
    borderBottom: "1px solid #303030",
  };

  const techniqueCardStyle: CSSProperties = {
    background: "#141414",
    border: "1px solid #303030",
    marginBottom: "8px",
    borderRadius: "4px",
  };

  return (
    <Card
      title={
        <Title level={4} style={{ color: "#fff", margin: 0 }}>
          ATT&CK 矩阵
        </Title>
      }
      style={{ background: "#141414", marginTop: 24 }}
    >
      <Spin spinning={loading}>
        <div style={matrixStyle}>
          <Row wrap={false}>
            {tactics.map((tactic) => (
              <Col key={tactic.id} style={columnStyle}>
                <div style={tacticHeaderStyle}>
                  <Text type="secondary">{tactic.id}</Text>
                  <br />
                  <Text strong style={{ color: "#fff" }}>
                    {tactic.name}
                  </Text>
                </div>
                <div style={{ marginTop: "8px" }}>
                  {techniques
                    .filter((tech) => tech.ta_id === tactic.id)
                    .map((tech) => (
                      <div key={tech.t_id}>
                        {/* 主技术 */}
                        {tech.t_name && (
                          <Card
                            size="small"
                            style={{
                              ...techniqueCardStyle,
                              borderLeft: `3px solid ${getRiskColor(
                                tech.risk_level
                              )}`,
                            }}
                          >
                            <Text strong style={{ color: "#fff" }}>
                              {tech.t_id}
                            </Text>
                            <div style={{ marginTop: "4px" }}>
                              <Text type="secondary">{tech.t_name}</Text>
                            </div>
                            {tech.risk_level && (
                              <Tag
                                color={getRiskColor(tech.risk_level)}
                                style={{ marginTop: "4px" }}
                              >
                                风险等级 {tech.risk_level}
                              </Tag>
                            )}
                          </Card>
                        )}
                        {/* 子技术 */}
                        {tech.sub_t_data.map((subTech) => (
                          <Card
                            key={subTech.t_sub_id}
                            size="small"
                            style={{
                              ...techniqueCardStyle,
                              marginLeft: "16px",
                              borderLeft: `3px solid ${getRiskColor(
                                subTech.risk_level
                              )}`,
                            }}
                          >
                            <Text strong style={{ color: "#fff" }}>
                              {subTech.t_sub_id}
                            </Text>
                            <div style={{ marginTop: "4px" }}>
                              <Text type="secondary">{subTech.t_sub_name}</Text>
                            </div>
                            {subTech.risk_level && (
                              <Tag
                                color={getRiskColor(subTech.risk_level)}
                                style={{ marginTop: "4px" }}
                              >
                                {getRiskText(subTech.risk_level)}行为
                              </Tag>
                            )}
                          </Card>
                        ))}
                      </div>
                    ))}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Spin>
    </Card>
  );
};

export default AttackMatrix;
