import React, { useEffect, useState } from "react";
import "./App.css";
import { InboxOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { config } from "./config/index";
import {
  message,
  Upload,
  theme,
  UploadProps,
  ConfigProvider,
  Button,
  Modal,
  ModalProps,
  Space,
  Switch,
} from "antd";
import AppHeader from "./AppHeader";
import { request } from "./config/request";
const { Dragger } = Upload;

function App() {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("userInfo");
    if (!token) {
      navigate("/auth");
    }
  });
  // 处理文件上传的函数
  const handleUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    const data = await request.upload<{ task_id: string; sha1: string }>(
      "/api/upload",
      file
    );

    if (data) {
      message.success(`${file.name} 上传成功!开始分析.`);
      setTimeout(() => {
        navigate(`/analysis?taskId=${data.task_id}&sha1=${data.sha1}`);
      }, 3000);
    } else {
      message.error(`${file.name} 上传失败.可能服务器炸了`);
    }
    /*
    message.success(`${file.name} 上传成功!开始分析.`);
    setTimeout(() => {
      navigate(`/analysis?taskId=c441619e-e77e-42fa-b78e-a3a6c66b5cb1`);
    }, 3000);
    */
    setConfirmLoading(false);
  };

  const DraggerProps: UploadProps = {
    style: {
      width: "70vh",
    },
    beforeUpload: (file: any) => {
      setSelectedFile(file);
      setOpen(true);
      // 返回 false 阻止自动上传
      return false;
    },
    name: "file",
    multiple: false,
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleModalOk = async () => {
    if (!selectedFile) {
      message.error("没有选择文件");
      return;
    }

    setConfirmLoading(true);
    setOpen(false);

    // 确认后执行上传
    await handleUpload(selectedFile);
  };

  const handleModalCancel = () => {
    setSelectedFile(null);
    setOpen(false);
  };

  const ModalProps: ModalProps = {
    title: "上传确认",
    cancelText: "取消",
    okText: "确认上传",
    open: open,
    confirmLoading: confirmLoading,
    onOk: handleModalOk,
    onCancel: handleModalCancel,
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>冲鸭文件沙箱</p>
        <Dragger {...DraggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">点击这里或者拖动文件到这里进行上传</p>
          <p className="ant-upload-hint">
            最高支持100M的文件,支持.zip压缩包,压缩包密码支持
            无密码/infected/threatbook
          </p>
        </Dragger>
        <p>注意:目前内测时间是早上7点到晚上7点.其他时间沙箱不能使用!</p>
        <Modal {...ModalProps}>
          <p>
            上传会自动选择最佳环境,一旦上传,代表你已经知晓{" "}
            <a>这个沙箱是内部使用的,还在开发中,不对任何人负责</a>
          </p>
          <Space direction="vertical">
            <div>
              {selectedFile && (
                <p>
                  选择的文件: {selectedFile.name} (
                  {(selectedFile.size / 1024 / 1024).toFixed(2)} MB)
                </p>
              )}
            </div>
          </Space>
          <div>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked
              disabled
            />
            {" 分析完成后24小时内自动删除样本 "}
          </div>
        </Modal>
      </header>
    </div>
  );
}

export default App;
