import React, { useState, useEffect } from "react";
import {
  CaretDownOutlined,
  CaretRightOutlined,
  FolderOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { theme, message } from "antd";
import ProcessDetailPanel from "./ProcessDetailPanel";
import { IOAData, OperationsData, ProcessInfo } from "./types";
import IOADetailsPanel from "./IOADetailsPanel";
import { API_BASE_URL } from "./config";

interface MainIOA {
  ta_id: string;
  threat_level: string;
  t_id: string;
  ta_name: string;
  t_name: string;
}

interface ProcessTreeNode {
  collapsed: boolean;
  rpc_tag: number;
  is_many_child: boolean;
  p_id: string;
  p_name: string;
  p_uuid: string;
  pp_uuid: string;
  pp_name: string;
  pp_id: string;
  start_time: number;
  end_time: number;
  children: ProcessTreeNode[];
  main_ioa: MainIOA | null;
}

interface ProcessTreeProps {
  node: ProcessTreeNode;
  operationsData: OperationsData | undefined;
  onProcessClick?: (node: ProcessTreeNode) => void;
  selectedProcessId: string | undefined;
  selectedProcess: ProcessInfo | undefined;
  ioaData?: any;
  taskId?: string; // 添加 taskId 参数
}

const ProcessTreeComponent: React.FC<ProcessTreeProps> = ({
  node,
  operationsData,
  onProcessClick,
  selectedProcessId,
  selectedProcess,
  ioaData,
  taskId,
}) => {
  const { token } = theme.useToken();
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [isHovered, setIsHovered] = React.useState(false);
  const hasChildren = node.children && node.children.length > 0;

  const toggleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const processNodeStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    padding: "8px 12px",
    marginBottom: "4px",
    borderRadius: token.borderRadiusLG,
    cursor: "pointer",
    transition: "all 0.2s ease",
    backgroundColor:
      selectedProcessId === node.p_uuid
        ? "rgba(24, 144, 255, 0.1)"
        : isHovered
        ? "rgba(255, 255, 255, 0.04)"
        : "transparent",
    borderLeft: node.main_ioa
      ? "4px solid rgba(255, 77, 79, 0.8)"
      : "4px solid transparent",
  };

  const toggleButtonStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    border: "none",
    borderRadius: "4px",
    background: isHovered ? "rgba(255, 255, 255, 0.08)" : "transparent",
    cursor: "pointer",
    padding: 0,
    marginRight: "8px",
    transition: "all 0.2s ease",
    color: "rgba(255, 255, 255, 0.65)",
  };

  const processIconStyle: React.CSSProperties = {
    fontSize: "16px",
    marginRight: "8px",
    color: "rgba(255, 255, 255, 0.85)",
  };

  const nameStyle: React.CSSProperties = {
    fontSize: "14px",
    fontWeight: 500,
    color: "rgba(255, 255, 255, 0.85)",
  };

  const pidStyle: React.CSSProperties = {
    fontSize: "12px",
    color: "rgba(255, 255, 255, 0.45)",
    marginLeft: "8px",
    fontFamily: "monospace",
  };

  const alertStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    marginLeft: "16px",
    padding: "3px 8px",
    borderRadius: token.borderRadiusSM,
    backgroundColor: "rgba(255, 77, 79, 0.15)",
  };

  const alertTextStyle: React.CSSProperties = {
    fontSize: "12px",
    color: "rgba(255, 77, 79, 0.85)",
    marginLeft: "6px",
  };

  const childrenContainerStyle: React.CSSProperties = {
    marginLeft: "28px",
    borderLeft: "1px solid rgba(255, 255, 255, 0.12)",
    paddingLeft: "20px",
  };

  return (
    <div>
      <div
        onClick={() => onProcessClick?.(node)}
        style={processNodeStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          {hasChildren && (
            <button
              onClick={toggleExpand}
              style={toggleButtonStyle}
              onMouseEnter={(e) => {
                e.currentTarget.style.background = "rgba(255, 255, 255, 0.12)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = isHovered
                  ? "rgba(255, 255, 255, 0.08)"
                  : "transparent";
              }}
            >
              {isExpanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
            </button>
          )}

          <FolderOutlined style={processIconStyle} />

          <div style={nameStyle}>
            {node.p_name}
            <span style={pidStyle}>({node.p_id})</span>
          </div>

          {node.main_ioa && (
            <div style={alertStyle}>
              <WarningOutlined
                style={{ color: "rgba(255, 77, 79, 0.85)", fontSize: "12px" }}
              />
              <span style={alertTextStyle}>
                {node.main_ioa.t_name} - {node.main_ioa.ta_name}
              </span>
            </div>
          )}
        </div>
      </div>
      {selectedProcessId === node.p_uuid && (
        <>
          <ProcessDetailPanel
            processInfo={selectedProcess}
            operationsData={operationsData}
          />
          {ioaData?.data?.data?.ioa_data && (
            <IOADetailsPanel ioaData={ioaData.data.data.ioa_data} />
          )}
        </>
      )}
      {hasChildren && isExpanded && (
        <div style={childrenContainerStyle}>
          {node.children.map((child) => (
            <ProcessTreeComponent
              key={child.p_uuid}
              node={child}
              onProcessClick={onProcessClick}
              selectedProcessId={selectedProcessId}
              selectedProcess={selectedProcess}
              ioaData={ioaData}
              taskId={taskId}
              operationsData={operationsData}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProcessTreeComponent;
